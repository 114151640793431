import React from "react";
import { Seo } from "../components/seo";
import Stores from "../components/Stores/Stores";
import Layout from "../hoc/Layout/Layout";

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Här finns vi" />
      <Stores />
    </Layout>
  );
};

export default IndexPage;
